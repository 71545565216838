.product_components {
    padding: 10px;
}

.product_components .product_components_box {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 20px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    min-height: 200px;
}

.product_components_box .bg {
    position: absolute;
    bottom: -6px;
    left: 0;
}

.product_components_box .bg img {
    width: 400px;
    height: 40px;
    object-fit: cover;
}

.product_components_box_item h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    color: hsla(0, 0%, 17%, 0.8);
    margin: 0px 0px 7px 0px;
    text-transform: capitalize;
    width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.product_components_box_item h4 {
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    color: hsla(0, 0%, 17%, 0.8);
    margin: 0px 0px 7px 0px;
}

.product_components_box_item h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: hsla(0, 0%, 17%, 0.8);
    margin: 0;
}

.product_components_box_item h5 span {
    margin-right: 5px;
}

.component_weight {
    margin-top: 40px;
    display: flex;
    align-items: center;
}

.component_weight .component_weight_item h4 {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    color: hsla(0, 0%, 17%, 0.5);
    margin: 0px 0px 7px 0px;
}

.component_weight .component_weight_item h5 {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    margin: 0;
}

.component_weight_item2 {
    margin-left: 50px;
}

.view_edit .view {
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    text-transform: unset;
    border: 1px solid hsla(95, 50%, 51%, 1);
    border-radius: 50px;
    padding: 1px 10px;
    background-color: hsla(95, 100%, 93%, 1);
    color: #78c144;
}

.view_edit {
    display: flex;
    align-items: center;
}

.view_edit .view:hover {
    background-color: hsla(95, 100%, 93%, 1);
}

.view_edit .edit {
    color: #78c144;
    padding: 0;
}

.view_edit .disable_deactive {
    display: none;
}

.view_edit .edit:hover {
    background-color: unset;
}

.product_components_box_main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.add_product_name .add_product_name_content .add_product_name_list h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    margin: 0 0 7px 0;
}

.add_product_name .add_product_name_content .add_product_name_list {
    display: flex;
    align-items: flex-start;
}

.add_product_name .add_product_name_content .add_product_name_list .product h4 {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin: 0 0 7px 0;
}

.add_product_name .add_product_name_content .add_product_name_list .product h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: hsla(0, 0%, 17%, 0.8);
    margin: 0;
}

.add_product_name .add_product_name_content .add_product_name_list .product h5 span {
    margin-right: 5px;
}

.add_product_name .add_product_name_content .add_product_name_list .view_edit .edit {
    justify-content: flex-start;
    margin-left: 8px;
}

.add_product_name .add_product_name_content .add_product_name_list .view_edit .edit svg {
    width: 19px;
}

.product_item_code {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.product_item_code_content {
    width: 33.3%;
}

.product_item_code_content h4 {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin: 0 0 4px 0;
    color: hsla(0, 0%, 17%, 0.5);
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product_item_code_content h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin: 0;
    color: hsla(0, 0%, 17%, 0.8);
}

.substrate_description {
    margin-top: 30px;
}

.substrate_description h5 {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin: 0 0 4px 0;
    color: hsla(0, 0%, 17%, 0.5);
}

.substrate_description p {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin: 0;
    color: hsla(0, 0%, 17%, 0.8);
    width: 500px;
}

.table_body .edit_delete {
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.table_body .edit_delete button {
    /* display: block; */
    /* padding: 0; */
    /* min-width: 36px; */
}

.table_body .edit_delete .delete_button svg:hover {
    color: hsla(0, 93%, 68%, 1) !important;
}

.delete_sales_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.delete_sales_list h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    margin: 0 0 8px 0;
    color: hsla(0, 0%, 17%, 1);
}

.delete_sales_list p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin: 0;
    color: hsla(0, 0%, 17%, 0.7);
    width: 330px;
    text-align: center;
}

.delete_sales_list .cancel_save_user {
    margin-top: 20px;
}

.delete_sales_list .cancel_save_user .save_user {
    padding: 05px 20px !important;
}
.view_edit button:last-child{
    display: flex !important;
    justify-content: flex-start !important;
    min-width: 30px;
}
.view_edit button:nth-child(2){
    min-width: 45px;
}

@media (min-width:320px) and (max-width:767px) {
    .product_item_code_content h5 {
        font-size: 11px !important;
    }
}