.root {
    padding-top: rem(80px);
    padding-bottom: rem(80px);
}

.label {
    text-align: center;
    font-weight: 900;
    font-size: rem(38px);
    line-height: 1;
    margin-bottom: calc(1.5 * var(--mantine-spacing-xl));
    color: var(--mantine-color-gray-2);


}

/* Spinner.css */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #7986cb;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}


.description {
    max-width: rem(500px);
    margin: auto;
    margin-top: var(--mantine-spacing-xl);
    margin-bottom: calc(1.5 * var(--mantine-spacing-xl));
}

.title {
    font-family:
        Greycliff CF,
        var(--mantine-font-family);
    text-align: center;
    font-weight: 900;
    font-size: rem(38px);

}

.filter_filed button {
    border: 1px solid hsla(95, 50%, 51%, 1);
    color: #78c144;
    background-color: unset !important;
    padding: 10px 13px;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    text-transform: unset;
    border-radius: 5px;
}

.download_options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.download_options button {
    gap: 5px;
}

.filter_filed button svg {
    margin-right: 05px;
    font-size: 18px;
}

.filter_filed {
    display: flex;
    gap: 10px;
}

.filter_filed button:hover {
    background-color: unset !important;
}

#simple-popover .MuiPopover-paper {
    width: 330px !important;
    /* left: 0px !important; */
    border-radius: 10px !important;
    padding: 20px 15px;
}

.filter_list .filter_list_item h3 {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin: 0;
}

.filter_list_item_files {
    margin-top: 20px;
    margin-left: -5px;
}

.filter_list_item_files .InputGroup {
    display: flex;
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    flex-wrap: wrap;
}

.filter_list_item_files input[type="radio"] {
    visibility: hidden;
    height: 0;
    width: 0;
}

.filter_list_item_files label {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    color: white;
    padding: 4px 14px;
    border-radius: 6px;
    transition: color --transition-fast ease-out,
        background-color --transition-fast ease-in;
    user-select: none;
    margin-right: 8px;
    border: 1px solid hsla(0, 0%, 68%, 1);
    color: hsla(0, 0%, 68%, 1);
    border-radius: 50px;
    white-space: nowrap;
}

.filter_list_item_files label:last-of-type {
    margin-right: 0;
    margin-top: 10px;
}

.filter_list_item_files input[type="radio"]:checked+label {
    border: 1px solid #78c144;
    background-color: hsla(95, 100%, 93%, 1);
    color: #78c144;
}

.filter_list_item_files input[type="radio"]:hover:not(:checked)+label {
    background-color: hsla(95, 100%, 93%, 1);
    color: #78c144;
}

.filter_dates {
    margin-top: 20px;
}

.filter_dates .MuiFormControl-root {
    width: 100% !important;
}

.filter_dates .MuiFormControl-root label {
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.filter_dates .MuiFormControl-root input {
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.rest-apply {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.rest-apply .reset {
    font-family: "Poppins", sans-serif !important;
    color: hsla(0, 0%, 63%, 1);
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    padding: 05px 30px;
    margin-right: 10px;
    border: 1px solid hsla(0, 0%, 63%, 1);
    text-transform: unset;
}

.rest-apply .reset:hover {
    color: hsla(0, 0%, 63%, 1);
    background-color: #FFF;
}

.rest-apply .apply {
    color: #FFF;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    padding: 05px 30px 6px 30px;
    margin-right: 10px;
    background-color: hsla(95, 50%, 51%, 1);
    text-transform: unset;
}

.rest-apply .apply:hover {
    background-color: hsla(95, 50%, 51%, 1);
    color: #FFF;
}




@media (min-width:320px) and (max-width:480px) {
    #simple-popover .MuiPopover-paper {
        left: 0px !important;
    }
}

@media (min-width:480px) and (max-width:600px) {
    #simple-popover .MuiPopover-paper {
        left: 93px !important;
    }
}

@media (min-width:600px) and (max-width:767px) {
    #simple-popover .MuiPopover-paper {
        left: 269px !important;
    }
}

@media (min-width:768px) and (max-width:991px) {
    #simple-popover .MuiPopover-paper {
        left: 483px !important;
    }
}

@media (min-width:992px) and (max-width:1024px) {
    #simple-popover .MuiPopover-paper {
        left: 605px !important;
    }
}

@media (min-width:1025px) and (max-width:1300px) {
    #simple-popover .MuiPopover-paper {
        left: 865px !important;
    }
}

@media (min-width:1301px) and (max-width:1500px) {
    #simple-popover .MuiPopover-paper {
        left: 1100px !important;
    }
}