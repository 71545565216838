.right_bar {
    margin: 10px;
}

.right_bar .topnav_bar {
    background-color: #FFF;
    padding: 15px 10px;
    border-radius: 04px;
}

.topnav_bar ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topnav_bar ul li {
    list-style: none;
}

.topnav_bar ul .topnav_bar_list h3 {
    font-size: 20px;
    font-weight: 800;
    line-height: 27px;
    margin: 0;
}

.menu_user {
    display: flex;
    align-items: center;
}
.menu_user img{
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}
.menu_user svg {
    color: #7e8c9a;
    margin-left: 10px;
}

.menu_item button:hover {
    background-color: unset !important;
}

.MuiMenu-root .MuiList-padding .MuiMenuItem-root {
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
}

.MuiMenu-root .MuiList-padding .MuiMenuItem-root:hover {
    background-color: hwb(95 27% 24%);
    color: #FFF;
}

.MuiMenu-root .MuiList-padding a {
    color: #000;
    text-decoration: none;
}

/* importproductfile */
.import_products {
    background-color: #FFF;
    margin: 30px 16px 0px 0px;
    padding: 20px 30px;
}

.import_products .MuiBox-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.import_products .MuiBox-root h5 {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: #000;
}

.import_products .MuiBox-root .MuiButton-root {
    background-color: hwb(95 27% 24%) !important;
    color: #FFF;
    padding: 08px 10px;
    text-transform: unset;
    font-family: "Poppins", sans-serif !important;
    border-color: #3b8108;
}

.import_products .MuiBox-root .MuiButton-root .MuiSvgIcon-root {
    font-size: 19px;
    margin-right: 5px;
}

.import_products_rules {
    margin-top: 30px;
    background-color: #F2F3F4;
    padding: 30px 20px;
    border-radius: 5px;
}

.import_products_rules h5 {
    font-family: "Poppins", sans-serif !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #000;
}

.import_products_rules h5 span {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #000;
}

.import_products_rules ul {
    margin: 20px 0px 0px 0px;
}

.import_products_rules ul li {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #000;
    margin-bottom: 10px;
}

.upload_product {
    padding: 80px 0px 0px 0px;
}

.upload_product .MuiButton-root {
    background-color: hwb(95 27% 24%) !important;
    color: #FFF;
    padding: 08px 10px;
    text-transform: unset;
    font-family: "Poppins", sans-serif !important;
    border-color: #3b8108;
}

.upload_product .MuiButton-root .MuiSvgIcon-root {
    font-size: 19px;
    margin-right: 5px;
}

.upload_product .uploaad_product_file {
    margin-top: 30px;
}

.upload_product .uploaad_product_file input {
    border: 1px solid hsla(0, 0%, 51%, 1) !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    padding: 8px 10px;
    border-radius: 4px;
}

.upload_product .uploaad_product_file .MuiButton-root {
    background-color: hwb(95 27% 24%) !important;
    color: #FFF;
    padding: 6px 10px;
    text-transform: unset;
    font-family: "Poppins", sans-serif !important;
    border-color: #3b8108;
    margin-left: 20px;
}


.upload_product .uploaad_product_file input[type=file]::file-selector-button {
    background-color: hwb(95 27% 24%) !important;
    color: #FFF;
    border: 1px solid #3b8108 !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    padding: 3px 6px;
    border-radius: 4px;
    margin-right: 15px;
    text-transform: unset;
}

.modal_main .MuiBox-root {
    position: 'absolute' !important;
    top: '50%' !important;
    left: '50%' !important;
    transform: 'translate(-50%, -50%)' !important;
    background-color: 'white' !important;
    box-shadow: 24 !important;
    padding: 40px !important;
    width: 400px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    border-radius: 3px !important;
}

.modal_main .errorMessage {
    font-family: "Poppins", sans-serif !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    color: #000 !important;
    text-align: center !important;
    margin-bottom: 20px !important;
}

.modal_main .errorMessage img {
    width: 30px;
    /* height: 30px; */
}

.modal_main .errorMessage_1 {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #000;
    text-align: center !important;
    margin-bottom: 25px !important;
}

.modal_main .MuiBox-root .MuiButton-root {
    background-color: hwb(95 27% 24%) !important;
    color: #FFF;
    padding: 6px 17px;
    text-transform: unset;
    font-family: "Poppins", sans-serif !important;
    border-color: #3b8108;

}