.download_pdf .menu_item button {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    text-transform: unset;
    color: hsla(0, 0%, 17%, 0.8) !important;
}

.download_pdf .menu_item button:hover {
    color: hsla(95, 50%, 51%, 1);
}

/* .download_pdf_menu .MuiPaper-rounded {
    top: 230px !important;
} */

.download_pdf_menu .MuiMenu-list .MuiMenuItem-gutters img {
    margin-right: 10px;
}

.download_pdf_menu .MuiMenu-list .MuiMenuItem-gutters {
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
}

.download_pdf_menu .MuiPopover-paper {
    width: 120px;
}

.download_pdf_menu .MuiMenu-list .MuiMenuItem-gutters:hover {
    background-color: hwb(95 27% 24%);
    color: #FFF;
}

.filter_dates .MuiFormControl-root .MuiInputAdornment-root svg {
    fill: #3b8108;
}

.MuiPickersCalendarHeader-root .MuiPickersArrowSwitcher-root .MuiPickersArrowSwitcher-button svg {
    fill: hsla(0, 0%, 15%, 1) !important;
}

.MuiPickersPopper-paper {
    border-radius: 10px !important;
}

.MuiDayCalendar-header .MuiTypography-caption {
    font-family: "Inter", sans-serif !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: hsla(240, 6%, 25%, 0.3) !important;
}

.MuiPickersFadeTransitionGroup-root .MuiPickersCalendarHeader-label {
    font-family: "Inter", sans-serif !important;
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
}

.MuiPickersDay-dayWithMargin {
    font-family: "Inter", sans-serif !important;
    font-size: 14px !important;
    line-height: 24px !important;
    font-weight: 500 !important;
    border: unset !important;
    color: hsla(0, 0%, 0%, 1);
}

.MuiPickersDay-today:not(.Mui-selected) {
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    font-weight: 800 !important;
    background: linear-gradient(256.47deg, #006838 0%, #77C043 50%, #B3CE35 100%);
    color: #FFF !important;
    border: unset !important;
}

.MuiPickersDay-root.Mui-selected {
    font-size: 12px !important;
    font-weight: 800 !important;
    background: linear-gradient(256.47deg, #006838 0%, #77C043 50%, #B3CE35 100%);
    color: #FFF !important;
    border: unset !important;
}