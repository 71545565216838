@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: "Poppins", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
    background-color: hsla(0, 0%, 95%, 1);
}


.sidebar-main {
    padding: 40px 10px 30px 10px;
    background-color: #FFF;
    height: 100vh;
    /* width: 234px; */
}

.sidebar-main .sidebar-image {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 30px;
    cursor: pointer;
}

.sidebar-main .sidebar-image img:nth-child(2) {
    margin-top: 30px;
}

.sidebar-main .sidebar-image h6 {
    color: #2A2C36;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 5px 0px;
}

.sidebar-main .sidebar-image p {
    color: #939AAC;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.sidebar_menu_list .MuiAccordion-rounded {
    box-shadow: unset;
    border-radius: unset !important;
    color: hsla(0, 0%, 51%, 1);
    font-family: "Manrope", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 22px;
}

.sidebar_menu_list .MuiAccordion-rounded span img {
    margin-right: 10px;
}

.user_management_accordion .Mui-expanded {
    margin: 0 !important;
    min-height: 48px;
    align-items: center;
}

.sidebar_menu_list .product_setup .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(180deg) !important;
    fill: #FFF;
}


.sidebar_menu_list .MuiAccordionSummary-content {
    margin: 0px !important;
}

.sidebar_menu_list .logomenu {
    margin-bottom: 20px;
    margin-left: 30px;
}

.sidebar_menu_list .MuiAccordion-rounded::before {
    background-color: unset;
}


.sidebar_menu_list .user_management_accordion.Mui-expanded {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.sidebar_menu_list .product_setup {
    margin: 5px 0 !important;
}

.sidebar_menu_list .MuiAccordionSummary-gutters {
    min-height: 48px !important;
    padding: 10px 15px !important;
}

.user_management_accordion ul li {
    display: flex;
    padding: 10px 16px;
    margin-bottom: 10px;

}

.user_management_accordion ul {
    padding: 0px;
    margin: 0px;
}

.user_management_accordion {
    color: hsla(0, 0%, 51%, 1);
    font-family: "Manrope", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 22px;
    text-decoration: none;
}

.user_management_accordion .MuiSvgIcon-root {
    margin-right: 10px;
    font-size: 20px;
    color: #828282;
}

.user_management_accordion .active {
    background-color: hsla(95, 50%, 51%, 1) !important;
    color: #FFF !important;
    padding: 10px 15px !important;
}

.user_management_accordion .active a {
    color: #FFF !important;
}

.user_management_accordion .active .MuiSvgIcon-root {
    color: #FFF !important;
}

.sidebar_menu_list .MuiAccordionDetails-root ul .active a {
    color: hsla(95, 50%, 51%, 1) !important;
}

.user_management_accordion .MuiSvgIcon-root:last-child {
    font-size: 24px !important;
    margin: 0px 0px 0px 20px;
    color: #828282;
}

.sidebar_menu_list .product_setup .MuiAccordionSummary-content svg {
    margin-right: 10px;
    font-size: 20px;
}

.sidebar_menu_list .MuiAccordionDetails-root ul {
    padding: 0px 0px 0px 33px;
    margin: 10px 0px 0px 0px;
    list-style: none;
}

.sidebar_menu_list .MuiAccordionDetails-root ul li {
    padding-bottom: 10px;
}

.sidebar_menu_list .MuiAccordionDetails-root ul li a {
    color: hsla(0, 0%, 51%, 1);
    font-size: 12px;
    line-height: 22px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600;
    text-decoration: none;
}

.sidebar_menu_list .MuiAccordionDetails-root {
    padding: 0px !important;
}

.sidebar_menu_list .product_content_sm {
    display: flex;
    align-items: center;
}

.product_content_sm.active {
    color: white !important;
    background-color: hsla(95, 50%, 51%, 1) !important;
}

.product_content_sm span {
    display: flex;
}

.product_content_sm span .MuiSvgIcon-root {
    margin-right: 10px;
    font-size: 20px;
}

.product_content_sm.active .MuiSvgIcon-root {
    color: white;

}
.sidebar_menu_list a {
    text-decoration: none !important;
    color: rgb(13 13 13 / 83%) !important;
}

.active .product_content_sm {
    color: white !important;
    background-color: hsla(95, 50%, 51%, 1) !important;
}
.nav_img{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.user_management_accordion .active .nav_img{
    filter: invert();
}
.MuiPaper-elevation1 .active .nav_img{
    filter: invert();
}

/* .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
    background-color: hwb(95 27% 24%) !important;
    color:  #FFF !important;
} */

@media (min-width:320px) and (max-width:767px) {
    .MuiDrawer-paperAnchorDockedLeft {
        width: 200px !important;
        height: 500px !important;
    }

    .sidebar-main {
        padding: 40px 10px 30px 10px;
        background-color: #FFF;
        height: unset;
        /* width: 100%; */
    }

}

@media (min-width:768px) and (max-width:991px) {
    .sidebar-main {
        height: unset;
        width: 100%;
    }
}

@media(min-width:900px) and (max-width:991px) {
    .sidebar-main .sidebar-image {
        margin-left: 0px !important;
    }
}

@media (min-width:992px) and (max-width:1024px) {
    .sidebar-main .sidebar-image {
        margin-left: 0px !important;
    }
    .sidebar-main .sidebar-image {
        margin-left: 0px !important;
    }
}