.app-page-profile__card {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 4px #eaeaea;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    padding: 35px 20px;
    width: 100%;
}

.app-page-profile {
    padding: 80px 30px 40px;
}

.app-page-profile__wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
}

.app-page-profile__image {
    align-self: center;
    height: 80px;
    width: 80px;
}

.app-page-profile__image img {
    border-radius: 50%;
    height: 80px;
    object-fit: cover;
    width: 80px;
    cursor: pointer;
}

.app-page-profile__infolist {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.app-page-profile__infolist p {
    border-bottom: 1px solid black;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
    font-style: normal !important;
    color: hsla(0, 0%, 17%, 0.8);
    font-size: 12px;
    margin: 0px;
}

.app-page-profile__infolistitem {
    margin-top: 30px;
}

.app-page-profile__infolistitem h6 {
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
    font-style: normal !important;
    color: hsla(0, 0%, 17%, 0.8);
    font-size: 15px;
    margin-bottom: 18px;
}

.app-page-profile__infolistitem .MuiFormControl-root {
    width: 100% !important;
}

.app-page-profile__infolistitem .MuiTextField-root:focus-visible {
    border: 1px solid hsla(0, 0%, 17%, 0.8) !important;
}

.app-page-profile__card h2 {
    margin: 0px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
    font-style: normal !important;
    color: hsla(0, 0%, 17%, 0.8);
    font-size: 18px;
    text-align: center;
}

.app-page-profile__card .MuiButton-root {
    background-color: hwb(95 27% 24%) !important;
    color: #FFF;
    padding: 08px 10px;
    text-transform: unset;
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    border-color: #3b8108;
}

.app-page-profile__card .MuiButton-root:hover {
    background-color: hwb(95 27% 24%) !important;
    color: #FFF !important;
}

.app-page-profile .menu_item {
    margin-top: 20px !important;
}