body {
    margin: 0;
}

.login {
    background-color: #FFF;
    width: 100%;
    height: 100vh;
    position: relative;
}

.login .login_bg img {
    width: 100%;
}

.login .login_bg {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.login_banner .login_banner_wrapper {
    height: calc(100vh);
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_banner_wrapper_content_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login_banner_wrapper_content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login_banner_wrapper_content h2 {
    font-family: "Poppins", sans-serif !important;
    font-size: 36px;
    font-weight: 800;
    line-height: 49px;
    margin: 0 0 4px 0;
}

.login_banner_wrapper_content p {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    margin: 0;
    text-align: center;
    color: hsla(0, 0%, 17%, 1);
}

.login_form {
    z-index: 9999;
    width: 500px;
    margin-top: 30px;
}

.login_form .MuiFormControl-root {
    width: 100%;
}

.login_form .MuiFormControl-root .MuiInputLabel-root {
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.MuiFormControl-root .MuiInputLabel-formControl.Mui-focused {
    color: #78c144 !important;
}

.MuiFormControl-root .MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #78c144 !important;
}
.MuiFormLabel-asterisk {
    color: #ff0000 !important;
}
.forgot_password {
    margin-top: 6px;
}

.forgot_password Button {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: hsla(264, 8%, 12%, 1);
    padding: 0;
    text-transform: unset;
}

.forgot_password Button:hover {
    background-color: unset;
}

.login_button {
    width: 100%;
    margin-top: 30px;
}

.login_button Button {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-transform: unset;
    color: #FFF;
    background-color: hsla(95, 50%, 51%, 1);
    width: 100%;
}

.login_button Button:hover {
    color: #FFF;
    background-color: hsla(95, 50%, 51%, 1);
}

.login_banner_wrapper_content Button {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-transform: unset;
    color: #78c144;
}

.login_banner_wrapper_content Button:hover {
    background-color: unset;
}

.otp_verification {
    margin: 30px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.otp_verification input {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    width: 45px !important;
    height: 45px;
    border: 1px solid hsla(246, 14%, 82%, 1) !important;
    border-radius: 4px;
    position: relative;
}

.otp_verification input:focus-visible {
    outline: 1px solid hsla(95, 50%, 51%, 0.8) !important;
    box-shadow: 0px 0px 10px 0px hsla(95, 50%, 51%, 0.8);
}

.otp_verification input:not(:first-child) {
    margin-left: 30px;
}

.otp_verification span {
    font-size: 0;
}

.otp_verification .otp_inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.resend {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.resend Button {
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    color: hsla(0, 0%, 15%, 0.5);
    padding: 0;
    text-transform: unset;
    display: block;
    min-width: 48px;
}

.resend Button:hover {
    background-color: unset;
}

.resend p {
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    color: hsla(0, 0%, 15%, 0.5);
    padding: 0;
    margin: 0px 0px 0px 03px;
}

@media (min-width:320px) and (max-width:767px) {
    .login {
        height: unset !important;
    }

    .login_form {
        width: auto;
    }

    .login_banner .login_banner_wrapper {
        height: unset !important;
    }
}