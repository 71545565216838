.main_box .table_box {
    background-color: #FFF;
    margin: 10px;
    border-radius: 10px;
}

.table_inbox {
    border: 1px solid hsla(0, 0%, 0%, 0.1);
    margin: 10px;
    border-radius: 05px;
    width: auto !important;
}

.sidebar_menu_list {
    margin-top: 20px;
}

.add_user_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
}

.add_user_list .add_user_list_1 {
    display: flex;
    /* align-items: center; */
}

.add_user_list .add_user_content h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    margin: 0;
}

.add_user button {
    background-color: hwb(95 27% 24%) !important;
    color: #FFF;
    padding: 08px 10px;
    display: flex;
    align-items: flex-start;
    text-transform: unset;
    font-family: "Poppins", sans-serif !important;
    /* font-size: 0; */
    border-color: unset;

}

.add_user .import_menu .MuiIconButton-root {
    border: 1px solid #3b8108 !important;
    border-radius: 4px !important;
    background-color: unset !important;
    padding: 8px !important;
}

.add_user .import_menu .MuiIconButton-root img {
    width: 22px;
    height: 24px;
    object-fit: contain;
}

.add_user_list_1 .import_menu {
    margin-right: 10px;
}

.add_user_list_1 .import_menu .MuiIconButton-root {
    color: #000 !important;
    border: 1px solid #3b8108 !important;
    border-radius: 4px !important;
    padding: 8px !important;
}

.add_user {
    display: flex;
}
.add_user_image{
    width: 18px;
    margin-right: 5px;
}

.add_user .import_menu {
    margin-right: 10px;
}

.add_user_list_1 .import_menu img {
    width: 22px;
    height: 24px;
    object-fit: contain;
    /* filter: grayscale(10); */
}

/* .add_user_list_1 .import_menu img:hover {
    filter: grayscale(100%) brightness(1.2) sepia(100%) hue-rotate(70deg);
} */

/* .add_user_list_1 .import_menu img:active {
    filter: grayscale(100%) brightness(1.2) sepia(100%) hue-rotate(70deg);
} */

.add_user button:hover {
    border-color: unset;
}

.MuiDialog-paperScrollPaper {
    width: 800px;
    max-width: 900px !important;
}

.sales_entry .MuiDialog-paperScrollPaper {
    width: 550px;
    max-width: 550px;
}

.sales_list .MuiDialog-paperScrollPaper {
    width: 600px;
    max-width: 600px;
}

.sales_delete .MuiDialog-paperScrollPaper {
    width: 360px;
    max-width: 360px;
    border-radius: 10px;
}

.MuiDialogTitle-root {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    text-transform: capitalize;
}

.MuiIconButton-sizeMedium:hover {
    background-color: unset !important;
}

.adduser_bg .user_form .MuiFormControl-root {
    width: 100%;
}

.adduser_bg .user_form .MuiFormControl-root .MuiInputLabel-root {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: hsla(264, 8%, 12%, 1);
    /* background-color: #FFF; */
}

.adduser_bg .user_form .product_name .css-14lo706 {
    width: 80px !important;
}

.adduser_bg .user_form .MuiOutlinedInput-input {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.adduser_bg .user_form .MuiOutlinedInput-notchedOutline {
    border-color: hsla(0, 0%, 51%, 1) !important;
}

.table_body .active_inactive .active_inactive_content ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.table_body .active_inactive .active_inactive_content ul li {
    list-style: none;
}

.table_body .active_inactive .active_inactive_content ul li:not(:first-child) {
    margin-left: 10px;
}

.table_body .active_inactive .active_inactive_content ul li img {
    margin-top: 3px;
}

.table_body .active_inactive .active_inactive_content ul li .MuiInput-underline::before {
    border: unset !important;
}

.table_body .active_inactive .active_inactive_content ul li .MuiInput-underline::after {
    border-bottom: unset !important;
}

.table_body .active_inactive .active_inactive_content ul li .MuiInput-underline option {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.table_body .active_inactive .active_inactive_content ul li .MuiInput-underline {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px
}

.cancel_save_user {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 40px;
}

.cancel_save_user .cancel {
    font-family: "Poppins", sans-serif !important;
    color: hsla(0, 0%, 63%, 1);
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    padding: 05px 30px;
    margin-right: 10px;
    border: 1px solid hsla(0, 0%, 63%, 1);
    text-transform: unset;
}

.cancel_save_user .cancel:hover {
    background-color: unset;
}

.cancel_save_user .save_user {
    color: #FFF;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    padding: 05px 30px 6px 30px;
    margin-right: 10px;
    background-color: hsla(95, 50%, 51%, 1);
    text-transform: unset;
    display: flex;
    align-items: center;
}

.cancel_save_user .save_user:hover {
    background-color: hsla(95, 50%, 51%, 1);
}

.add_user button span.content {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.add_user button:hover {
    background-color: unset;
}

.add_user button .icon svg {
    width: 18px;
    margin-right: 5px;
    margin-bottom: -7px;
}

.table_inbox .table_head {
    background-color: hsla(220, 20%, 97%, 1);
}

.table_body .edit_button:hover {
    background-color: unset;
}

.table_body .edit_button svg:hover {
    color: hwb(95 27% 24%) !important;
}

.table_body .edit_button svg {
    font-size: 18px;
    color: hsla(0, 0%, 51%, 1) !important;
}

.adduser_table .MuiTableCell-body {
    font-family: "Poppins", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
    color: hsla(0, 0%, 17%, 0.8);
    font-size: 12px;
    /* border-bottom: 1px thin hsla(0, 0%, 51%, 0.6) !important; */
    padding: 05px 08px 08px 20px;
    white-space: nowrap;
    text-transform: capitalize;
}
.user_management_main .adduser_table .MuiTableCell-body:nth-child(3){
    text-transform: lowercase;
}

.adduser_table .MuiTableCell-head {
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
    font-style: normal !important;
    color: hsla(0, 0%, 17%, 1);
    font-size: 12px;
    width: 130px !important;
    padding: 06px 0px 06px 20px;
    white-space: nowrap !important;
}

.table_body {
    border-top: 1px solid #DDD !important;
}

.table_inbox .pagination_table {
    width: 100%;
    margin: 60px 0px 20px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.table_inbox .pagination_table .MuiPagination-ul {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.user_form .MuiFormControl-root .MuiInputAdornment-root svg {
    fill: #78c144;
}

.download_options .MuiButtonBase-root {
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    width: 100%;
    color: rgba(0, 0, 0, 0.87);
    text-transform: unset;
    display: flex;
    justify-content: flex-start;
    border-radius: unset;
}

.download_options .MuiButtonBase-root:hover {
    background-color: hwb(95 27% 24%);
    color: #FFF;
    border-radius: unset;
}

.download_options_main {
    width: 90px;
    height: 60px;
    padding: 10px 0px;
}

/* extra styles */

.table_body .MuiButton-root {
    min-width: 0px !important;
}

.add_user .disable_deactive {
    display: none;
}

.add_user_list .disable_deactive {
    display: none !important;
}

.table_head .disable_deactive {
    display: none;
}

.table_body .disable_deactive {
    display: none;
}

.adduser_bg .user_form .MuiFormLabel-root {
    color: #1D1B20;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}

.adduser_bg .user_form .Mui-checked {
    color: #3b8108 !important;
}


.adduser_bg .user_form .MuiFormControlLabel-label {
    color: #1D1B20;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}
.edit_btn_tb{
    filter: grayscale();
}
.edit_btn_tb:hover{
    filter: unset;
}
@media (min-width:320px) and (max-width:767px) {
    .table_inbox {
        width: fit-content !important;
    }

    .adduser_bg .user_form .MuiFormControlLabel-root {
        margin: 0px !important;
    }

    .adduser_bg .user_form .MuiFormGroup-root {
        display: flex;
        flex-direction: column;
    }

    .cancel_save_user .save_user {
        font-size: 12px;
        line-height: 14px;
        padding: 4px 20px 4px 20px
    }
}

@media (min-width:768px) and (max-width:991px) {
    .sales_inbox {
        width: fit-content !important;
    }
}

@media (min-width:992px) and (max-width:1024px) {
    /* .adduser_table .MuiTableCell-body {
        font-size: 10px !important;
    } */
}